import paths from "./paths";

export { paths };

export const SESSION_TOKEN_NAME = "appacademia-session";
export const SESSION_TOKEN_DURATION = 30 * 12 * 3600;
export const SESSION_BUSINESSES = "appacademia-businesses";

export const SESSION_CURRENT_BUSINESS = "appacademia-current-business";
export const SESSION_USERNAME_KEY = "appacademia-key";
export const SESSION_BUSINESS_NAME = "appacademia-name";
export const SESSION_BUSINESS_ID = "appacademia-business_id";
export const SESSION_BUSINESS_KEY = "appacademia-business_key";
export const SESSION_BUSINESS_SCHOOL_TYPE = "appacademia-business_school_type";
export const SESSION_USERNAME_NAME = "appacademia-username";
export const SESSION_CLASSROOMS = "appacademia-classrooms";
export const SESSION_EMPLOYEES = "appacademia-employees";
export const SESSION_EMPLOYE = "appacademia-employe";
export const SESSION_STUDENTS_NUMBER_NEW = "appacademia-students_number_new";
export const SESSION_STUDENTS_NUMBER_OLD = "appacademia-students_number_old";
export const SESSION_STUDENTS = "appacademia-students";
export const SESSION_STUDENTS1 = "appacademia-students1";
export const SESSION_STUDENTS2 = "appacademia-students2";
export const SESSION_STUDENTS3 = "appacademia-students3";
export const SESSION_STUDENTS4 = "appacademia-students4";
export const SESSION_BULLETINS = "appacademia-bulletins";
export const SESSION_LESSON_NOTES = "appacademia-lessonnotes";
export const SESSION_LESSON_NOTES1 = "appacademia-lessonnotes1";
export const SESSION_LESSON_NOTES2 = "appacademia-lessonnotes2";
export const SESSION_LESSON_NOTES3 = "appacademia-lessonnotes3";
export const SESSION_LESSON_NOTES4 = "appacademia-lessonnotes4";
export const SESSION_STUDENT = "appacademia-student";
export const USER = "appacademia-user";
export const USER_ID = "appacademia-user-id";
export const SESSION_LESSONS = "appacademia-lessons";
export const SESSION_LESSON = "appacademia-lesson";
export const SESSION_IS_MUTIPLES_SCHOOLS = "appacademia-is-mutilple-schools";
export const SESSION_PAYMENTS = "appacademia-payments";
export const SESSION_CLAIMINGS = "appacademia-claimings";
export const SESSION_OFFLINE_MODE = "appacademia-offline_mode";
export const SESSION_ANNONCES = "appacademia-annonce";
export const SESSION_ATTENDANCES = "appacademia-attendance";
export const SESSION_HOMEWORKS = "appacademia-homework";
export const SESSION_LOGO_IMAGE = "appacademia-LOGO_IMAGE";
export const SESSION_BREAKING_NEWS = "appacademia-SESSION_BREAKING_NEWS";
export const SESSION_QUIZ = "appacademia-SESSION_QUIZ";
export const SESSION_DOCUMENT = "appacademia-DOCUMENT";
export const SESSION_BABILLARD = "appacademia-BABILLARD";
export const SESSION_CHATS = "appacademia-CHATS";
export const SESSION_TOPICS= "appacademia-TOPICS";
export const SESSION_IS_QUICK_MARKS_FILLING = "appacademia-is-quick-mark-filling";

export const USER_PREFS = {
  THEME_KEY: "apppacademia-theme",
};
